import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import { SprkStack, SprkStackItem, SprkCard, SprkButton, SprkLink, SprkHeading, SprkText } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Using Spark`}</h1>
    <SprkStack itemSpacing="large" mdxType="SprkStack">
  <SprkStackItem mdxType="SprkStackItem">
    <SprkStack itemSpacing="medium" mdxType="SprkStack">
      <SprkStackItem mdxType="SprkStackItem">
        <SprkHeading element="h2" variant="displayThree" mdxType="SprkHeading">
          For Developers
        </SprkHeading>
      </SprkStackItem>
      <SprkStackItem mdxType="SprkStackItem">
        <SprkText variant="bodyTwo" additionalClasses="sprk-u-mbm sprk-u-Measure" mdxType="SprkText">
          For every Spark component, we provide usage guidelines as well as developer documentation.
        </SprkText>
        <SprkText variant="bodyTwo" additionalClasses="sprk-u-Measure" mdxType="SprkText">
          There are Storybook sandboxes for Spark HTML, Angular, and React components.
        </SprkText>
      </SprkStackItem>
    </SprkStack>
  </SprkStackItem>
  <SprkStackItem mdxType="SprkStackItem">
    <div className="docs-o-Grid docs-o-Grid--three-col docs-o-Grid--large">
      <SprkCard mdxType="SprkCard">
        <SprkStack itemSpacing="large" additionalClasses="sprk-o-Stack__item sprk-c-Card__content" mdxType="SprkStack">
          <SprkStackItem mdxType="SprkStackItem">
            <SprkHeading element="h3" variant="displayFive" mdxType="SprkHeading">
              HTML Components
            </SprkHeading>
          </SprkStackItem>
          <SprkStackItem mdxType="SprkStackItem">
            <SprkText variant="bodyTwo" mdxType="SprkText">
              Explore the technical documentation for the HTML Spark components.
            </SprkText>
          </SprkStackItem>
          <SprkStack additionalClasses="sprk-o-Stack__item sprk-o-Stack--end-column" mdxType="SprkStack">
            <SprkStackItem mdxType="SprkStackItem">
              <SprkButton variant="secondary" element="a" href="https://html.sparkdesignsystem.com/" mdxType="SprkButton">
                HTML Storybook
              </SprkButton>
            </SprkStackItem>
          </SprkStack>
        </SprkStack>
      </SprkCard>
      <SprkCard mdxType="SprkCard">
        <SprkStack itemSpacing="large" additionalClasses="sprk-o-Stack__item sprk-c-Card__content" mdxType="SprkStack">
          <SprkStackItem mdxType="SprkStackItem">
            <SprkHeading element="h3" variant="displayFive" mdxType="SprkHeading">
              Angular Components
            </SprkHeading>
          </SprkStackItem>
          <SprkStackItem mdxType="SprkStackItem">
            <SprkText variant="bodyTwo" mdxType="SprkText">
              Explore the technical documentation for the Angular Spark components.
            </SprkText>
          </SprkStackItem>
          <SprkStack additionalClasses="sprk-o-Stack__item sprk-o-Stack--end-column" mdxType="SprkStack">
            <SprkStackItem mdxType="SprkStackItem">
              <SprkButton variant="secondary" element="a" href="https://angular.sparkdesignsystem.com/" mdxType="SprkButton">
                Angular Storybook
              </SprkButton>
            </SprkStackItem>
          </SprkStack>
        </SprkStack>
      </SprkCard>
      <SprkCard mdxType="SprkCard">
        <SprkStack itemSpacing="large" additionalClasses="sprk-o-Stack__item sprk-c-Card__content" mdxType="SprkStack">
          <SprkStackItem mdxType="SprkStackItem">
            <SprkHeading element="h3" variant="displayFive" mdxType="SprkHeading">
              React Components
            </SprkHeading>
          </SprkStackItem>
          <SprkStackItem mdxType="SprkStackItem">
            <SprkText variant="bodyTwo" mdxType="SprkText">
              Explore the technical documentation for the React Spark components.
            </SprkText>
          </SprkStackItem>
          <SprkStack additionalClasses="sprk-o-Stack__item sprk-o-Stack--end-column" mdxType="SprkStack">
            <SprkStackItem mdxType="SprkStackItem">
              <SprkButton variant="secondary" element="a" href="https://react.sparkdesignsystem.com/" mdxType="SprkButton">
                React Storybook
              </SprkButton>
            </SprkStackItem>
          </SprkStack>
        </SprkStack>
      </SprkCard>
    </div>
  </SprkStackItem>
  <SprkStackItem mdxType="SprkStackItem">
    <SprkStack itemSpacing="medium" mdxType="SprkStack">
      <SprkStackItem mdxType="SprkStackItem">
        <SprkHeading element="h2" variant="displayThree" mdxType="SprkHeading">
          For Designers
        </SprkHeading>
      </SprkStackItem>
      <SprkStackItem mdxType="SprkStackItem">
        <SprkText variant="bodyTwo" additionalClasses="sprk-u-mbm sprk-u-Measure" mdxType="SprkText">
          Spark components were built to solve a majority of your design solutions. Review our component guidelines to learn when and how to use them.
        </SprkText>
        <SprkText variant="bodyTwo" additionalClasses="sprk-u-Measure" mdxType="SprkText">
          To access the Spark UI Kit, email&nbsp;
          <SprkLink element="a" href="mailto://sparkdesignsystem@rocketmortgage.com" mdxType="SprkLink">
            sparkdesignsystem@rocketmortgage.com
          </SprkLink>
        </SprkText>
      </SprkStackItem>
    </SprkStack>
  </SprkStackItem>
    </SprkStack>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      